//export const GENERIC_API_URL = 'https://lcappapi.azurewebsites.net/';
export const GENERIC_API_URL = '/api/';
//export const GENERIC_API_URL = 'https://lcappv2testapi.azurewebsites.net/';
export const AZURE_MARKET_PLACE_URL = 'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/orxagrid1584097142796.lcappv2?tab=Overview';
export const TENANTS = 'derapp.onmicrosoft.com';
export const SIGN_IN_POLICY = "B2C_1_sign_up_sign_in";
export const TENANT_SUB_DOMAIN = TENANTS.split(".")[0];
const INSTANCE = `https://${TENANT_SUB_DOMAIN}.b2clogin.com/tfp/`;
export const SIGN_IN_AUTHORITY = `${INSTANCE}${TENANTS}/${SIGN_IN_POLICY}`;
export const APPLICATION_ID = "b249bf9e-9569-4c14-b12a-46b2563b2090";
//export const GENERIC_API_URL = 'http://localhost:5000/';

export const HELPTEXT = {
    ADDRESS: 'Enter your site location using an address or post code / zip code, or select your site on the map',
    BUILDING_NAME: 'Enter a name of each of your buildings.',
    BUILDING_TYPE: 'Select what is the building typically used for.',
    COMPASS_TOOL: 'Using the compass tool on the map, select the direction in which the best roof of the building is facing (prefer south-facing roofs).',
    ROOF_SLOPE: 'Enter the roof slope (or pitch), measured in degrees from the horizontal, using the roof angle tool.',
    ROOF_SIZE: 'Enter approximate roof size in square meters, then click ‘Add Building’',
    ELECTRICITY_COST: 'Enter the price you pay your electricity supplier per unit in your local currency. You can find this information on your energy bills. If you pay a dual or variable tariff, then enter an average estimated value here.',
    EXPORT_TARIFF: 'Export tariff is a bonus payment for every unit of surplus electricity your system exports to the electricity grid',
    DEMAND_FILE: 'If you have a smart meter installed by your energy supplier at your site, then you can obtain a csv file of your energy consumption from the meter and upload here. Your energy supplier can help you get this file in case you do not have it. Most standard half-hourly billing formats are accepted.',
    BUILDING_DEMAND: 'Upload electricity consumption files for each of your buildings if available, as a csv file. Most standard half-hourly billing formats are accepted.',
    BUILDING: 'If you don’t have the smart meter demand files for your site or your buildings, then enter your approximate annual consumption for each building. Select the relevant building from dropdown and then click Add.',
    SOLAR_SETUP: 'Enter the local Solar PV system lifetime in years. Many solar systems can produce electricity for 25 or more years, but we have put 20 years as the default. As you explore different system providers, you can enter and change the expected years to see how much you can save over different time periods.',
    PV_INSTALLER: 'Enter the standard rate for 1kW of solar panels and installation in your local currency. If you have quotes from installers in your area, then you could enter the amount here or you could leave it as default.',
    BATTERY_SETUP: 'Enter the local Battery Energy Storage system lifetime in charging cycles or accept the default values.',
    BATTERY_INSTALLATION: 'Enter the standard rate for 1kWh of battery storage and installation in your local currency. If you have quotes from installers in your area, then you could enter the amount here or you could leave it as default.',
    INSTALL_EV_CHARGERS: 'If you plan to install Electric Vehicle Charging Points, specify the number of chargers for each applicable building, clicking ‘Add’ each time.'
};
