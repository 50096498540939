import React from 'react';

const SUbNav = ({ handleSubNavigation, option, maxReachedStep }) => {
    switch (option) {
        case 1:
            return (
                <div className="row mt-4 mb-3">
                    <div className="col-lg-3 d-flex align-items-center">
                        <span className='formSubMenu' ><b> Location</b></span> /
              <span className='formSubMenu'
                            onClick={(e) => maxReachedStep >= 1 && handleSubNavigation('Utility')}>
                            Utility
                            </span> /
              <span
                            className='formSubMenu'
                            onClick={(e) => maxReachedStep >= 2 && handleSubNavigation('Installation')}>
                            Installation
                            </span>
                    </div>
                    <div className="col-lg-6 text-center"><h3>To start, tell us about your site. </h3></div>
                </div>);
            break;

        case 2:
            return (
                <div className="row mt-4 mb-3">
                    <div className="col-lg-3 d-flex align-items-center">
                        <span className='formSubMenu' onClick={(e) => handleSubNavigation('Location')}>Location</span> /
                <span className='formSubMenu' ><b>Utility</b></span> /
                <span className='formSubMenu' onClick={(e) => maxReachedStep >= 2 && handleSubNavigation('Installation')}>Installation</span>
                    </div>
                    <div className="col-lg-6 text-center"><h3>Now, tell us about your existing utilities.</h3></div>
                </div>
            );
            break;
        case 3:
            return (<div className="row mt-4 mb-3">
                <div className="col-lg-3 d-flex align-items-center">
                    <span className='formSubMenu' onClick={(e) => handleSubNavigation('Location')}>Location</span> /
                    <span className='formSubMenu' onClick={(e) => handleSubNavigation('Utility')}>Utility</span> /
                    <span className='formSubMenu' ><b>Installation</b></span>
                </div>
                <div className="col-lg-6 text-center"><h3>To start, tell us about your site. </h3></div>
            </div>);
            break;
    }
}

export default SUbNav;
