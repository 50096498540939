import React, { Component } from "react";

import rightArrow from "../img/right_arrow.png";
import leftArrow from "../img/left_arrow.png";
import addFileButton from "../img/addFileButton.png";
import CustomSlider from "./CustomSlider";
import axios from "axios";
import { GENERIC_API_URL, HELPTEXT } from "../common/constants";
import LeftArrowButton from "../common/LeftArrowButton";
import RightArrowButton from "../common/RightArrowButton";
import AppBanner from "../common/AppBanner";
import TitleWithInfo from "../common/TitleWithInfo";
import Footer from "./Footer";
import SiteSummaryPage from "./SiteSummaryPage";
import { Spinner } from "react-bootstrap";
import BottomNavigationBar from "../common/BottomNavigationBar";
// import StartPage from './StartPage';

export default class UtilityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            isCompassVisible: true,
            building_data: [],
            hasDemandFile: false,
            hasDemandFileBldg: false,
            isUploadingDemandFile: false,
            isUploadingDemandFileBldg: false,
            isUploadingUtilityValues: false,
            fileHandle: "",
            fileHandleBldg: "",
            showSiteSummary: true,
            showBldgRemoveFileButton: false,
            annualConsumptionMarks: [
                {
                    value: 0,
                    label: `0 kWh`,
                },
                {
                    value: 10000,
                    label: "10,000 kWh",
                },
            ],
        };
        this.uploadProfile = this.uploadProfile.bind(this);
        this.removeFileAction = this.removeFileAction.bind(this);
        this.setAnnualConsumption = this.setAnnualConsumption.bind(this);
        this.updateBuilding = this.updateBuilding.bind(this);
        this.hasDemandFileChange = this.hasDemandFileChange.bind(this);
        this.hasDemandFileBldgChange = this.hasDemandFileBldgChange.bind(this);
        this.clearFileHandleBldg = this.clearFileHandleBldg.bind(this);
        this.updateBuildingDemand = this.updateBuildingDemand.bind(this);
        this.updateBuildingData = this.updateBuildingData.bind(this);
        this.removeBldgFileAction = this.removeBldgFileAction.bind(this);
    }

    componentWillMount() {
        if (
            this.props.load_profile_csv_optional !== undefined &&
            this.props.load_profile_csv_optional !== ""
        ) {
            this.setState({ hasDemandFile: true });
        }
        this.setState({ building_data: this.props.building_data });
        // setTimeout(()=>{
        // let AppHeader = document.getElementsByClassName('App-header')[0];
        // AppHeader.style.background='white';
        // },500);
    }

    toggleSiteSummary = () => {
        let { showSiteSummary } = this.state;
        this.setState({ showSiteSummary: !showSiteSummary });
    };

    populateAnnualConsOrDemandFile = (e) => {
        let { building_data } = this.state;
        let uplProMessageBldgEle = document.getElementById("load_profile_csv_optional_bldg_status");
        uplProMessageBldgEle.innerHTML = '';
        let datafileB1Ele = document.getElementById('datafileB1');
        let building = building_data.filter(function (building) {
            return building.building_name === e.target.value;
        });
        console.log('building found', building)
        if (building.length === 0) {
            uplProMessageBldgEle.innerHTML = "";
            datafileB1Ele.disabled = 'disabled';

        } else {
            console.log('building.annual_kwh_consumption_optional', building.annual_kwh_consumption_optional)
            console.log('load_profile_csv_optional_bldg_fname', building.load_profile_csv_optional_bldg_fname)
            datafileB1Ele.disabled = '';
            if (building[0].annual_kwh_consumption_optional) {
                document.getElementById('annual_kwh_consumption_optional').value = 'annual_kwh_consumption_optional'
            }
            if (building[0].load_profile_csv_optional_bldg_fname) {
                uplProMessageBldgEle.innerHTML = "Uploaded " + building[0].load_profile_csv_optional_bldg_fname;
                this.setState({ showBldgRemoveFileButton: true })
            }
        }
    }

    hasDemandFileChange = (e) => {
        //console.log('hasDemandFileChange',e.target.checked)
        this.setState({ hasDemandFile: e.target.checked });
    };

    hasDemandFileBldgChange = (e) => {
        //console.log('hasDemandFileChange',e.target.checked)
        this.setState({ hasDemandFileBldg: e.target.checked });
    };

    setAnnualConsumption(value) {
        let annualConEle = document.getElementById(
            "annual_kwh_consumption_optional"
        );
        if (annualConEle !== null) {
            annualConEle.value = value;
        }

        return value;
    }

    clearFileHandleBldg() {
        this.setState({ fileHandleBldg: "" });
    }

    async uploadProfile(e, type) {
        let isBuilding = type === "site" ? false : true;
        console.log("uploadprofile", this.props, isBuilding, type);
        // this.setState({isUploadingFile:true});
        var bodyFormData = new FormData();
        var fileEle = '';
        var fileToUpload = e.target.files[0]; //document.getElementById('datafile').value;
        var fileToUploadName = '';
        if (fileToUpload) { fileToUploadName = fileToUpload.name; }
        // console.log('before upload fileToUpload',this.props,fileToUpload,e.target)
        bodyFormData.append("file", fileToUpload);
        bodyFormData.append("lat", this.props.latitude);
        bodyFormData.append("lon", this.props.longitude);
        // console.log('before upload form',bodyFormData)
        if (isBuilding) {
            this.setState({ isUploadingDemandFileBldg: true });
            fileEle = document.getElementById("load_profile_csv_optional_bldg")
        } else {
            this.setState({ isUploadingDemandFile: true });
            fileEle = document.getElementById("load_profile_csv_optional")
        }
        console.log('fileEle', fileEle)
        await axios({
            method: "post",
            url: GENERIC_API_URL + "upload",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                console.log("upload response", response.data.handle);
                fileEle.value = response.data.handle;
                if (isBuilding) {
                    let uplProMessageBldgEle = document.getElementById(
                        "load_profile_csv_optional_bldg_status"
                    );
                    uplProMessageBldgEle.innerHTML = "Uploaded " + fileToUploadName; //+response.data.handle;
                } else {
                    let uplProMessageEle = document.getElementById(
                        "load_profile_csv_optional_status"
                    );
                    uplProMessageEle.innerHTML = "Uploaded " + fileToUploadName; //+response.data.handle;
                    let uplSiteFileNameEle = document.getElementById("load_profile_csv_optional_site_fname");
                    uplSiteFileNameEle.value = fileToUploadName;
                    document.getElementById('hasDemandFileBldg').disabled = 'disabled';
                }
            })
            .catch(function (error) {
                //handle error
                // console.log('upload response err',error.response);
                if (error.response !== undefined) {
                    if (isBuilding) {
                        let uplProMessageBldgEle = document.getElementById(
                            "load_profile_csv_optional_bldg_status"
                        );
                        uplProMessageBldgEle.innerHTML =
                            "Error Occurred! " + error.response.data.error;
                    } else {
                        let uplProMessageEle = document.getElementById(
                            "load_profile_csv_optional_status"
                        );
                        uplProMessageEle.innerHTML =
                            "Error Occurred! " + error.response.data.error;
                    }
                }
            });
        if (isBuilding) {
            this.setState({ fileHandleBldg: fileEle.value, fileBldgName: fileToUploadName, isUploadingDemandFileBldg: false, showBldgRemoveFileButton: true });
        } else {
            this.setState({ fileHandle: fileEle.value, fileSiteName: fileToUploadName, isUploadingDemandFile: false });
        }
        this.updateAllChangesToForm();
        // this.forceUpdate();
        // console.log('upload success',this.state.fileHandle);
    }

    removeFileAction() {
        this.setState({ fileHandle: "" });
        document.getElementById("load_profile_csv_optional").value = "";
        this.props.handleChangeInput("load_profile_csv_optional");
        let uplProMessageEle = document.getElementById(
            "load_profile_csv_optional_status"
        );
        uplProMessageEle.innerHTML = ''
    }

    removeBldgFileAction() {
        let { building_data } = this.state;
        let uplProMessageEle = document.getElementById(
            "load_profile_csv_optional_bldg_status"
        );
        uplProMessageEle.innerHTML = ''
        console.log("upd building_data", building_data);
        var building = building_data.filter(function (building) {
            let selectedBuildingEle = document.getElementById(
                "building_list_up"
            ); //.options.selected.value;
            let buildingOptions = [];
            let selectedBuilding = "";
            if (selectedBuildingEle !== null) {
                buildingOptions = selectedBuildingEle.options;
                selectedBuilding =
                    buildingOptions[selectedBuildingEle.selectedIndex];
                console.log("selectedBuilding", selectedBuilding.value);
            }
            return building.building_name === selectedBuilding.value;
        });
        delete building[0].load_profile_csv_optional_bldg;
        delete building[0].load_profile_csv_optional_bldg_fname;
        this.setState({ building_data: building_data, showBldgRemoveFileButton: false, fileHandleBldg: '' });
        console.log('bldg with removed file', building, building_data)
        this.updateBuildingData(building_data);

    }

    updateBuildingData(newBuildingData) {
        this.setState({ building_data: newBuildingData });
        this.props.sendBuildingsDataToForm(newBuildingData);
    }

    updateBuildingDemand() {
        let { fileHandleBldg } = this.state;
        if (fileHandleBldg !== "") {
            this.updateBuilding("load_profile_csv_optional_bldg", fileHandleBldg);
            this.updateBuilding("load_profile_csv_optional_bldg_fname", this.state.fileBldgName);
        } else {
            let annualConEle = document.getElementById(
                "annual_kwh_consumption_optional"
            );
            if (annualConEle !== null) {
                this.updateBuilding(
                    "annual_kwh_consumption_optional",
                    annualConEle.value
                );
            }
        }
        this.updateAllBuildingsToForm();
    }

    updateBuilding(field, value) {
        let { building_data } = this.props;
        console.log("upd building_data", building_data);
        var building = building_data.filter(function (building) {
            let selectedBuildingEle = document.getElementById(
                "building_list_up"
            ); //.options.selected.value;
            let buildingOptions = [];
            let selectedBuilding = "";
            if (selectedBuildingEle !== null) {
                buildingOptions = selectedBuildingEle.options;
                selectedBuilding =
                    buildingOptions[selectedBuildingEle.selectedIndex];
                console.log("selectedBuilding", selectedBuilding.value);
            }
            return building.building_name === selectedBuilding.value;
        });
        // console.log("building", building);
        if (building !== null && building.length > 0) {
            building[0][field] = value;
        }
    }

    updateAllChangesToForm() {
        this.props.handleChangeInput("import_cost_kwh");
        this.props.handleChangeInput("export_price_kwh");
        this.props.handleChangeInput("load_profile_csv_optional");
        this.props.handleChangeInput("load_profile_csv_optional_site_fname");
    }

    updateAllBuildingsToForm() {
        console.log("UP updating all building to form");
        let { building_data } = this.state;
        this.props.sendBuildingsDataToForm(building_data);
    }

    render() {
        let { hasDemandFile, hasDemandFileBldg } = this.state;
        let {
            building_data,
            resolveApiError,
            resolveApiResponseStatus,
            free_calls,
            max_free_calls,
        } = this.props;
        // console.log('utility step',this.props,this.state)
        let buildingOptions = [];
        if (building_data !== undefined && building_data.length > 0) {
            for (let i = 0; i < building_data.length; ++i) {
                buildingOptions.push(
                    <option value={building_data[i].building_name}>
                        {building_data[i].building_name}
                    </option>
                );
            }
        }
        // console.log('buildingOptions',buildingOptions)
        return (
            <div>
                {resolveApiError !== undefined && resolveApiError.length > 0 && (
                    <div id="AppBanner">
                        <div className="alert alert-danger" role="alert">
                            {resolveApiError}
                        </div>
                    </div>
                )}
                {resolveApiResponseStatus === 200 &&
                    <AppBanner free_calls={free_calls} max_free_calls={max_free_calls} />}
                <div className="col-lg-12" style={{ background: "transparent" }}>
                    <div className="row">
                        <div
                            className="col-lg-4 p-2"
                        >
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Electricity Costs
                                </div>

                                <div className="row pt-1">
                                    <div className="col">
                                        <div className="form-group">
                                            <TitleWithInfo
                                                title={`What is electricity cost per unit?`}
                                                info={HELPTEXT.ELECTRICITY_COST} />
                                            <div>
                                                <input
                                                    type="number"
                                                    style={{ width: '30%', }}
                                                    id="import_cost_kwh"
                                                    step="0.01"
                                                    defaultValue={
                                                        this.props.import_cost_kwh !==
                                                            undefined
                                                            ? this.props.import_cost_kwh
                                                            : 0.14
                                                    }
                                                    onChange={()=> this.props.handleChangeInput("import_cost_kwh")}
                                                />
                                                  {!this.props.currency && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                                {` ${this.props.currency} `}per kwh
                                            </div>
                                            &nbsp;
                                        </div>
                                        <br />
                                        <div className="form-group">
                                            <TitleWithInfo title={`What is your export tariff?`}
                                                info={HELPTEXT.EXPORT_TARIFF} />
                                            <div>
                                                <input
                                                    type="number"
                                                    style={{ width: '30%' }}
                                                    id="export_price_kwh"
                                                    step="0.01"
                                                    defaultValue={
                                                        this.props.export_price_kwh !==
                                                            undefined
                                                            ? this.props.export_price_kwh
                                                            : 0.04
                                                    }
                                                    onChange={()=> this.props.handleChangeInput("export_price_kwh")}
                                                />
                                                 {!this.props.currency && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                                {` ${this.props.currency} `}per kwh                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 p-2"
                        >
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Electricity Demand for entire site
                                </div>

                                <div className="row pt-1">
                                    <div className="col">
                                        <div className="form-group">
                                            <TitleWithInfo title={`Do you have your site demand file?`}
                                                info={HELPTEXT.DEMAND_FILE} />
                                            <label
                                                className="switch"
                                                style={{
                                                    position: "absolute",
                                                    right: "24px",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="hasDemandFile"
                                                    onChange={
                                                        this.hasDemandFileChange
                                                    }
                                                    defaultChecked={
                                                        this.state.hasDemandFile
                                                    }
                                                />
                                                <span
                                                    className="slider"
                                                    style={{ color: "white" }}
                                                >
                                                    &nbsp;&#10004;
                                            </span>
                                            </label>
                                        </div>
                                        <br />
                                        <div
                                            className="demandFileBox"
                                            style={{
                                                display: hasDemandFile
                                                    ? "block"
                                                    : "none",
                                            }}
                                        >
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                id="datafileB"
                                                name="datafileB"
                                                onChange={(e) => {
                                                    this.uploadProfile(e, "site");
                                                }}
                                            />
                                            <label
                                                id="uploadBuildingProfileBtn"
                                                style={{
                                                    width: "100%",
                                                    margin: "0px",
                                                    padding: "0px",
                                                    cursor: "pointer",
                                                }}
                                                htmlFor="datafileB"
                                            >
                                                <input
                                                    id="load_profile_csv_optional"
                                                    type="text"
                                                    defaultValue={
                                                        this.props
                                                            .load_profile_csv_optional
                                                    }
                                                    style={{ display: "none" }}
                                                />
                                                <img
                                                    src={addFileButton}
                                                    style={{ width: "100px" }}
                                                    alt=""
                                                />
                                                <br />
                                            Add your site demand file
                                        </label>
                                            <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex", marginBottom: '1rem' }}>
                                                <span id="load_profile_csv_optional_status">
                                                    {this.props
                                                        .load_profile_csv_optional !==
                                                        undefined &&
                                                        this.props
                                                            .load_profile_csv_optional !==
                                                        ""
                                                        ? "File Alread Uploaded"
                                                        : ""}
                                                </span>
                                                {this.state.fileHandle &&
                                                    <div>
                                                        <button
                                                            className="btn btn-sm btn-success pull-right"
                                                            onClick={this.removeFileAction}
                                                        >
                                                            Remove File
                                                </button>
                                                    </div>
                                                }
                                                {this.state.isUploadingDemandFile && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-2">
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Electricity Demand for each building
                                </div>

                                <div className="row pt-1 pb-1">
                                    <div className="col">
                                        <div className="form-group">
                                            <TitleWithInfo
                                                title="Select your building"
                                                info={HELPTEXT.BUILDING} />

                                            <select id="building_list_up">
                                                <option value="">
                                                    --Please Select--
                                                </option>
                                                {buildingOptions}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <TitleWithInfo
                                                title="Do you have your building demand file?"
                                                info={HELPTEXT.BUILDING_DEMAND}
                                                required />
                                            <label
                                                className="switch"
                                                style={{
                                                    position: "absolute",
                                                    right: "24px",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="hasDemandFileBldg"
                                                    onChange={
                                                        this.hasDemandFileBldgChange
                                                    }
                                                    disabled={this.props.load_profile_csv_optional}
                                                />
                                                <span
                                                    className="slider"
                                                    style={{ color: "white" }}
                                                >
                                                    &nbsp;&#10004;
                                            </span>
                                            </label>
                                        </div>


                                        <div
                                            className="form-question"
                                            style={{
                                                display: !hasDemandFileBldg
                                                    ? "block"
                                                    : "none",
                                            }}
                                        >
                                            Annual Consumption
                                        <br />
                                            <input
                                                id="annual_kwh_consumption_optional"
                                                type="number"
                                                style={{ width: "70px" }}
                                                defaultValue={
                                                    this.props
                                                        .annual_kwh_consumption_optional !==
                                                        undefined
                                                        ? this.props
                                                            .annual_kwh_consumption_optional
                                                        : 2400
                                                }
                                            />
                                             {!this.props.currency && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            {" "}
                                        kWh

                                        </div>
                                        <div
                                            className="demandFileBoxForBuilding"
                                            style={{
                                                display: hasDemandFileBldg
                                                    ? "block"
                                                    : "none",
                                            }}
                                        >
                                            <br />
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                id="datafileB1"
                                                name="datafileB1"
                                                onChange={(e) => {
                                                    this.uploadProfile(
                                                        e,
                                                        "building"
                                                    );
                                                }}
                                            />
                                            <label
                                                id="uploadBuildingProfileBtn1"
                                                style={{
                                                    width: "100%",
                                                    margin: "0px",
                                                    padding: "0px",
                                                    cursor: "pointer",
                                                }}
                                                htmlFor="datafileB1"
                                            >
                                                <input
                                                    id="load_profile_csv_optional_bldg"
                                                    type="text"
                                                    // defaultValue={
                                                    //     this.props
                                                    //         .load_profile_csv_optional
                                                    // }
                                                    style={{ display: "none" }}
                                                />
                                                <img
                                                    src={addFileButton}
                                                    style={{ width: "100px" }}
                                                    alt=""
                                                />
                                                <br />
                                            Add your building demand file
                                        </label>
                                            <span id="load_profile_csv_optional_bldg_status"></span>
                                            {this.state.showBldgRemoveFileButton &&
                                                <div>
                                                    <button
                                                        className="btn btn-sm btn-success pull-right"
                                                        onClick={this.removeBldgFileAction}
                                                    >
                                                        Remove File
                                                </button>
                                                </div>
                                            }
                                        </div>
                                        <button
                                            className="btn btn-sm btn-success pull-right"
                                            disabled={this.state.isUploadingDemandFileBldg}
                                            onClick={this.updateBuildingDemand}
                                        >
                                            {this.state.isUploadingDemandFileBldg && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                           &nbsp; Add&nbsp;
                                    </button>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1">&nbsp;</div>
                    <SiteSummaryPage
                        showSiteSummary={this.state.showSiteSummary}
                        address={this.props.address}
                        page={'Utility'}
                        building_data={this.state.building_data}
                        updateBuildingToParentPage={this.updateBuildingData}
                    />

                    <BottomNavigationBar
                        onSiteSummaryClick={() => this.toggleSiteSummary()}
                        onLeftButtonClick={() => {
                            this.updateAllChangesToForm();
                            this.updateAllBuildingsToForm();
                            this.props.prevStep();
                        }}
                        onRightButtonClick={() => {
                            this.updateAllChangesToForm();
                            this.updateAllBuildingsToForm();
                            this.props.nextStep();
                        }} />

                </div>
            </div>
        );
    }
}
