import React, { Component } from "react";
import Footer from "./Footer";
import "../App.css";
import rightArrow from "../img/right_arrow.png";
import { AZURE_MARKET_PLACE_URL } from "../common/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class ResultsPage extends Component {
    componentDidMount() {
        var doc = document.getElementById('FileFrame').contentWindow.document;
        doc.open();
        doc.write(this.props.html);
        doc.close();
    }
    render() {
        return (
            <div className="row">
                <div className="col-lg-9">
                    <div className="row" style={{ background: "white" }}>
                        <div className="col-lg-12">
                            <div className="col">
                                <br></br>
                                <div className="col-lg-12">
                                    <iframe id="FileFrame" frameborder="0" width="100%" style={{ height: 'calc( 100vh - 180px )' }}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div
                        className="contents col-md-2"
                        style={{
                            marginTop: "10vh",
                            verticalAlign: "middle",
                            background: "white",
                        }}>
                        <div className="col control-label-heading">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={this.props.prevStep}
                            >
                                Back
                    </button>
                            <a href={AZURE_MARKET_PLACE_URL} target="_blank">
                                <button className="arrow-round-btn">
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        color={"#fff"}
                                    />
                                </button>
                            </a>
                        &nbsp; UPGRADE
                        <br />
                            <ul
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "200",
                                    marginTop: "20px",
                                    listStyle: "none",
                                }}
                            >
                                <li>
                                    &#10004;&nbsp;Run unlimited results for all your
                                    sites
                            </li>
                                <li>
                                    &#10004;&nbsp;Download detailed site report to
                                    analyse solar, storage, and EV potential
                            </li>
                                <li>&#10004;&nbsp;Custom input support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResultsPage;
