import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AZURE_MARKET_PLACE_URL } from "../common/constants";
import Footer from "./Footer";

export default class StartSitePage extends PureComponent {
    getPlanName(plan_id) {
        switch (plan_id) {
            case "m1":
                return "Basic plan";
            case "m2":
                return "Silver plan";
            case "m3":
                return "Gold plan";
            default:
                return "Free plan";
        }
    }

    renderMessage(plan_id, free_calls, max_free_calls) {
        if(plan_id === undefined) {
            return (<div></div>)
        }
        if (plan_id === null) {
            return (
                <div>
                    {plan_id}You have <b>{free_calls}</b>/{max_free_calls} remaining
                    results available on your {this.getPlanName(plan_id)},<br />{" "}
                    upgrade for unlimited results and sharing ability
                </div>
            );
        } else
        return (
            <div>
                You are subscribed to {this.getPlanName(plan_id)},<br /> upgrade
                for unlimited results and sharing ability
            </div>
        );
    }

    render() {


        let {
            resolveApiError,
            resolveApiResponseStatus,
            free_calls,
            max_free_calls,
            plan_id,
        } = this.props;

        let disabledNextButton =
            resolveApiResponseStatus !== 200 ||
            free_calls === null ||
            free_calls === 0;

        return (
            <div className='col-lg-12'>
                {resolveApiError !== undefined && resolveApiError.length > 0 && (
                    <div id="AppBanner">
                        <div className="alert alert-danger" role="alert">
                            {resolveApiError}
                        </div>
                    </div>
                )}

                <div className="row">
                <div className="col-md-6">
                    <div className="caption">
                        <div className="col-md-12">
                            <br />
                            <br />
                            <span className="d-flex">Welcome Back,</span>
                            <span>{this.props.firstName}</span>
                            <p>We hope you are having a wonderful day!</p>
                        </div>
                    </div>
                </div>
                    <div className="col-md-6">
                        <div className="col-md-10 offset-md-1">
                            <div
                                className="contents"
                                style={{
                                    borderRadius:'10%'
                                }}>
                                <div className="col-md-12" style={{padding:'3vw'}}>
                                    <div className="col mt-3 mt-5">
                                     <b>MODEL THE VALUE OF LOW CARBON
                                      AT YOUR LOCATION</b>
                                    </div>  
                                    <div className="col mt-3 mt-1">
                                        {this.renderMessage(
                                            plan_id,
                                            free_calls,
                                            max_free_calls
                                        )}
                                    </div>
                                    <br />
                                    <div className="text-center">
                                        <a
                                            className="btn btn-success-1"
                                            target="_blank"
                                            href={AZURE_MARKET_PLACE_URL}
                                        >
                                            UPGRADE
                                       </a>
                                    </div>
                                    <br />
                                    <button
                                            onClick={this.props.nextStep}
                                            disabled={disabledNextButton}
                                            className="arrow-round-btn"
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowRight}
                                                color={"#fff"}
                                            />
                                        </button>
                                        &nbsp; <button  
                                         onClick={this.props.nextStep}
                                         disabled={disabledNextButton}
                                         className = "text-btn"
                                        ><u>START A NEW SITE</u> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
