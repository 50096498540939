import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';

const TitleWithInfo = ({ title, required, info }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {title}
            {required && <span style={{ color: 'red' }}>*</span>} &nbsp;
            <FontAwesomeIcon
                onClick={handleShow}
                icon={faInfoCircle}
                size="1x"
                color="#B0B0B0"
            />

            <Modal show={show} onHide={handleClose} style={{ position: 'fixed', top: '50%', left: 'auto' }}>
                <Modal.Body>{info}</Modal.Body>
            </Modal>
        </>
    )
}
export default TitleWithInfo;
