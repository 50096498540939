import React, { Component } from 'react';
import rightArrow from '../img/right_arrow.png';

export default class StartPage extends Component {
    render() {
        return (
            <div className="align-self-center">
                <div>
                    <div className="caption responsive-box" style={{ fontWeight: '1000 !important' }}>
                        <div className="title">
                            Low Carbon
                        </div>
                        <div className="title">
                            Planning
                        </div>
                        <div className="caption-a sub-title mt-4">
                            Quickly and accurately calculate the optimal installation size of Solar,  Battery Storage or EV charging points.
                            {/* <div className="caption-a sub-title"> */}

                            {/* </div> */}
                        </div>
                    </div>
                </div>

                <div >
                    <div className="col-md-12 caption-a mt-4" >
                        <br />
                        <a className="btn btn-xs btn-success capsulebtn" href='/ad'>Sign in</a>
                        <a className="btn btn-xs btn-success capsulebtn ml-5" href='/b2c'>Try for free</a>
                    </div>
                </div>
            </div>
        );
    }

}
