import React, { Component } from "react";
import ReactGoogleMap from "./ReactGoogleMap";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import AnglePicker from "./AnglePicker";
import AnglePickerQuarter from "./AnglePickerQuarter";
import deleteButton from "../img/deleteButton.png";
import CustomSlider from "./CustomSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faArrowRight,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import LeftArrowButton from "../common/LeftArrowButton";
import RightArrowButton from "../common/RightArrowButton";
import AppBanner from "../common/AppBanner";
import TitleWithInfo from "../common/TitleWithInfo";
import Footer from "./Footer";
import SiteSummaryPage from "./SiteSummaryPage";
import { HELPTEXT } from "../common/constants";
import BottomNavigationBar from "../common/BottomNavigationBar";

export default class LocationDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            isCompassVisible: true,
            building_data: [],
            showSiteSummary: true,
            roofDirectionText: '',
            roofSizeMarks: [
                {
                    value: 0,
                    label: `0 \u33A1`,
                },
                {
                    value: 1000,
                    label: "1000 \u33A1",
                },
            ],
        };
        this.addBuilding = this.addBuilding.bind(this);
        // this.deleteBuilding = this.deleteBuilding.bind(this);
        this.getNextBuildingId = this.getNextBuildingId.bind(this);
        this.updateBuildingData = this.updateBuildingData.bind(this);
        this.azimuthRef = React.createRef();
        this.handleAzimuthChange = this.handleAzimuthChange.bind(this);
        this.hasBldgChange = this.hasBldgChange.bind(this);
    }

    componentWillMount() {
        console.log("componentWillMount LDP");
        this.setState({
            building_data: this.props.building_data,
            address: this.props.address,
            trigger: Math.random(),
        });
        this.handleAddressSetting(this.props.address);
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            address: nextProps["pac-input"],
        });
        this.handleAddressSetting(nextProps.address);
    }

    toggleSiteSummary = () => {
        let { showSiteSummary } = this.state;
        this.setState({ showSiteSummary: !showSiteSummary });
    };

    getDirection = (angle) => {
        var directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West'];
        return directions[Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8];
    }

    handleAzimuthChange() {
        let directionText = this.getDirection(this.azimuthRef.current.value)
        this.setState({ roofDirectionText: directionText })
        // console.log('handle azumuth',directionText,this.state.roofDirectionText,this.azimuthRef)
    }

    getNextBuildingId() {
        let { building_data } = this.state;
        // //console.log('getnextbuildingid ',building_data)
        if (building_data.length === 0) return 1;
        let buldingIds = [];
        for (let i = 0; i < building_data.length; ++i) {
            buldingIds.push(building_data[i].building_id);
        }
        // //console.log('buldingIds',buldingIds,Math.max(...buldingIds))
        return parseInt(Math.max(...buldingIds) + 1);
    }

    addBuilding() {
        if (this.state.building_data.length >= 6) {
            alert("Already uploaded required building data");
            return;
        }
        let { building_data } = this.state;
        // //console.log('add called')
        let building_name = document.getElementById("building_name");
        if (building_name.value === '') {
            alert('Please enter your building name.');
            window.scrollTo(0, 200);
            return;
        }
        let building_type = document.getElementById("building_type");
        if (building_type.value === '') {
            alert('Please select the type of building.');
            window.scrollTo(0, 200);
            return;
        }

        const existinBuilding = building_data.find(building => building.building_name === building_name.value);
        if (existinBuilding && existinBuilding.building_id) {
            alert('Please select a new name for the building.')
            window.scrollTo(0, 200);
            return;
        }
        // let num_ev_chargers = document.getElementById('num_ev_chargers');
        // let pv_size_kwp_optional = document.getElementById('pv_size_kwp_optional');
        // let annual_kwh_consumption_optional = document.getElementById('annual_kwh_consumption_optional');
        let roof_size_m2 = document.getElementById("roof_size_m2");
        let azimuth = document.getElementById("azimuth");
        let roofpitch = document.getElementById("roofpitch");
        // let load_buildling_profile_csv = document.getElementById('load_buildling_profile_csv');

        let building = {
            building_id: this.getNextBuildingId(),
            building_name: building_name.value,
            building_type: building_type.value,
            // num_ev_chargers:num_ev_chargers.value,
            // pv_size_kwp_optional:pv_size_kwp_optional.value,
            // annual_kwh_consumption_optional:annual_kwh_consumption_optional.value,
            roof_size_m2: roof_size_m2.value,
            azimuth_deg: azimuth.value,
            pitch_deg: roofpitch.value,
            // load_profile_csv:load_buildling_profile_csv.value,
        };
        building_data.push(building);
        // let bname='B'+(parseInt(building_data.length)+1);
        // //console.log('bname',bname)
        this.setState({ building_data: building_data });
    }

    updateBuildingData(newBuildingData) {
        this.setState({ building_data: newBuildingData });
        this.props.sendBuildingsDataToForm(newBuildingData);
    }

    toggleCompass = () => {
        let { isCompassVisible } = this.state;
        this.setState({ isCompassVisible: !isCompassVisible });
    };

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                //console.log('Success', latLng)
                this.setState({
                    address: address,
                    latLng: latLng,
                });
            })
            .catch((error) => console.error("Error", error));
    };

    handleAddressSetting = (address) => {
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                console.log("Success", latLng);
                this.setState({
                    address: address,
                    latLng: latLng,
                });
                document.getElementById("latitude").value = latLng.lat;
                document.getElementById("longitude").value = latLng.lng;
                this.props.handleChangeInput("pac-input");
                this.props.handleChangeInput("latitude");
                this.props.handleChangeInput("longitude");
                // let sideDetailsNextButton = document.getElementById('sideDetailsNextButton');
                // sideDetailsNextButton.disabled=false;
                // sideDetailsNextButton.addEventListener('click',this.handleNextClick);
                // //console.log('prop fun end')

                // this.props.handleChange(document.getElementById('pac-input'))
                // //console.log('address',address,latLng);
            })
            .catch((error) => console.error("Error", error));
    };

    setRoofSize(value) {
        let roofSizeEle = document.getElementById("roof_size_m2");
        //console.log('roofSizeEle',roofSizeEle)
        if (roofSizeEle !== null) {
            roofSizeEle.value = value;
        }

        return value;
    }

    updateAllChangesToForm() {
        this.props.handleChangeInput("pac-input");
        this.props.handleChangeInput("latitude");
        this.props.handleChangeInput("longitude");
    }

    updateAllBuildingsToForm() {
        console.log("LDP updating all building to form");
        let { building_data } = this.state;
        this.props.sendBuildingsDataToForm(building_data);
    }

    onRightButtonClick() {
        let { building_data } = this.state;
        this.updateAllChangesToForm();
        this.updateAllBuildingsToForm();
        if (building_data.length > 0) {
            this.props.nextStep();
        } else {
            window.scrollTo(0, 200);
            document.getElementById(
                "building_name"
            ).style.border = "1px solid red";
        }

    }

    hasBldgChange = (e) => {
        //console.log('hasDemandFileChange',e.target.checked)
        let buildingtype = document.getElementById("building_type");
        switch(buildingtype.value){
          case 'domestic':
            return(document.getElementById("roof_size_m2").value = 250);
          case 'work':
            return(document.getElementById("roof_size_m2").value = 250);
          case 'public':
            return(document.getElementById("roof_size_m2").value = 250);
          case 'commercial':
            return(document.getElementById("roof_size_m2").value = 1000);
          case 'delivery':
            return(document.getElementById("roof_size_m2").value = 250);
          default:
           return (document.getElementById("roof_size_m2").value = 250);
        }
    };
    

    render() {
        let { latLng, building_data } = this.state;
        let {
            resolveApiError,
            resolveApiResponseStatus,
            free_calls,
            max_free_calls,
        } = this.props;
        return (
            <div>
                {resolveApiError !== undefined && resolveApiError.length > 0 && (
                    <div id="AppBanner">
                        <div className="alert alert-danger" role="alert">
                            {resolveApiError}
                        </div>
                    </div>
                )}
                {resolveApiResponseStatus === 200 &&
                    <AppBanner free_calls={free_calls} max_free_calls={max_free_calls} />}
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Site Details
                            </div>

                                <div className="row" style={{ padding: "10px" }}>
                                    <div className="col form-question">
                                        <TitleWithInfo
                                            title={`What is your address?`}
                                            info={HELPTEXT.ADDRESS} />
                                        <PlacesAutocomplete
                                            value={this.state.address}
                                            onChange={this.handleChange}
                                            onSelect={this.handleAddressSetting}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                    <div>
                                                        <div //id="addressInput"
                                                            className=""
                                                            style={{ width: "100%" }}
                                                        >
                                                            <input
                                                                id="pac-input" //id="pac-input"
                                                                style={{
                                                                    marginBottom:
                                                                        "14px",
                                                                }}
                                                                {...getInputProps({
                                                                    placeholder:
                                                                        "Enter address here..",
                                                                    className:
                                                                        "location-search-input",
                                                                })}
                                                            />
                                                            {/* <span><img src={locationImg} style={{position:'absolute',right:'5px',width:'20px',}} /></span> */}
                                                        </div>
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && (
                                                                <div>Loading...</div>
                                                            )}
                                                            {suggestions.map(
                                                                (suggestion) => {
                                                                    const className = suggestion.active
                                                                        ? "suggestion-item--active"
                                                                        : "suggestion-item";
                                                                    // inline style for demonstration purpose
                                                                    const style = suggestion.active
                                                                        ? {
                                                                            backgroundColor:
                                                                                "#193A55",
                                                                            color:
                                                                                "#fff",
                                                                            cursor:
                                                                                "pointer",
                                                                        }
                                                                        : {
                                                                            backgroundColor:
                                                                                "#ffffff",
                                                                            cursor:
                                                                                "pointer",
                                                                        };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(
                                                                                suggestion,
                                                                                {
                                                                                    className,
                                                                                    style,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    suggestion.description
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </PlacesAutocomplete>
                                        <input
                                            id="latitude"
                                            name="latitude"
                                            type="text"
                                            defaultValue={
                                                latLng !== undefined
                                                    ? latLng.lat
                                                    : ""
                                            }
                                            style={{ display: "none" }}
                                        />
                                        <input
                                            id="longitude"
                                            name="longitude"
                                            type="text"
                                            defaultValue={
                                                latLng !== undefined
                                                    ? latLng.lng
                                                    : ""
                                            }
                                            style={{ display: "none" }}
                                        />

                                        <ReactGoogleMap
                                            selectedLatLng={this.state.latLng}
                                            handleNextClick={this.handleNextClick}
                                        />
                                        <AnglePicker
                                            isCompassVisible={
                                                this.state.isCompassVisible
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Building Details
                            </div>

                                <div className="row pt-2">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-question">
                                                    <TitleWithInfo required title={`What is your building name?`}
                                                        info={HELPTEXT.BUILDING_NAME} />
                                                    <input
                                                        type="text"
                                                        id="building_name"
                                                        required
                                                        placeholder="Enter a building name e.g. (B1, S1, Tower-1)"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-question">
                                                    <TitleWithInfo
                                                        required
                                                        title={`What type of building is it?`}
                                                        info={HELPTEXT.BUILDING_TYPE} />
                                                    <select id="building_type" required  onChange={
                                                        this.hasBldgChange
                                                    }>
                                                        <option value="">
                                                            --Please Select--
                                                        </option>
                                                        <option value="domestic">
                                                            Domestic
                                                        </option>
                                                        <option value="work">Work</option>
                                                        <option value="public">Public</option>
                                                        <option value="commercial">
                                                            Commercial
                                                        </option>
                                                        <option value="delivery">
                                                            Delivery
                                                        </option>
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                            &nbsp;
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-question">
                                                    <TitleWithInfo
                                                        title={`Select your roof Direction`}
                                                        info={HELPTEXT.COMPASS_TOOL} />
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={this.toggleCompass}
                                                    >
                                                        {this.state.isCompassVisible
                                                            ? "Hide"
                                                            : "Show"}{" "}
                                                        Compass on Map
                                                    </button>&nbsp;
                                                    <input
                                                        type="number"
                                                        id="azimuth"
                                                        ref={this.azimuthRef}
                                                        onChange={this.handleAzimuthChange}
                                                        style={{ width: "50px" }}
                                                    />  &deg;&nbsp;&nbsp;
                                                    {this.state.roofDirectionText}
                                                    <br />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-question">
                                                    <TitleWithInfo
                                                        title={`Select your roof slope.`}
                                                        info={HELPTEXT.ROOF_SLOPE} />
                                                    <div className="empty-box">
                                                        <AnglePickerQuarter />
                                                        <span
                                                            style={{
                                                                position: "relative",
                                                                bottom: "10px",
                                                            }}
                                                        >
                                                            <input
                                                                id="roofpitch"
                                                                type="number"
                                                                style={{ width: "40px" }}
                                                            />
                                                                &nbsp;&deg;
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-question">
                                                    <TitleWithInfo
                                                        title={`Select your roof Size.(in m2)`}
                                                        info={HELPTEXT.ROOF_SIZE} />
                                                    <input
                                                        type="number"
                                                        id="roof_size_m2"
                                                        defaultValue={250}
                                                        maxLength={5}
                                                        style={{ width: '75px' }}
                                                    />
                                                    <input
                                                        id="roofpitch"
                                                        type="number"
                                                        style={{ display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <button
                                            className="btn btn-success float-right"
                                            onClick={this.addBuilding}
                                        >
                                            Add Building
                                    </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <SiteSummaryPage
                        showSiteSummary={this.state.showSiteSummary}
                        address={this.props.address}
                        page={'Location'}
                        building_data={building_data}
                        updateBuildingToParentPage={this.updateBuildingData}
                    />
                    <BottomNavigationBar
                        onSiteSummaryClick={()=>this.toggleSiteSummary()}
                        onLeftButtonClick={()=>this.props.prevStep()}
                        onRightButtonClick={()=>this.onRightButtonClick()} />
                </div>
                {/* <Footer/> */}
            </div>
        );
    }
}
