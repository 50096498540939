import React from "react";
import LeftArrowButton from "./LeftArrowButton";
import RightArrowButton from "./RightArrowButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const BottomNavigationBar = ({ onSiteSummaryClick, onLeftButtonClick, onRightButtonClick, isOutput, children }) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="float-right mt-3">
                    <div
                        className="btn btn-success"
                        onClick={onSiteSummaryClick}>
                        Site Summary
                    </div>
                    &nbsp;
                        <LeftArrowButton
                        onButtonClick={onLeftButtonClick} />
                    &nbsp;
                    {isOutput ?
                        children
                        : <RightArrowButton
                            onButtonClick={onRightButtonClick} />}

                </div>
            </div>
        </div>
    );
};
export default BottomNavigationBar;
