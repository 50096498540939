import React, { Component } from "react";
import rightArrow from "../img/right_arrow.png";
import leftArrow from "../img/left_arrow.png";
import SiteSummaryPage from "./SiteSummaryPage";
// import CustomSlider from './CustomSlider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import LeftArrowButton from "../common/LeftArrowButton";
import AppBanner from "../common/AppBanner";
import TitleWithInfo from "../common/TitleWithInfo";
import Footer from "./Footer";
import { HELPTEXT } from "../common/constants";
import BottomNavigationBar from "../common/BottomNavigationBar";
var _ = require('lodash');

export default class PlannedInstallationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            address: "",
            isCompassVisible: true,
            building_data: [],
            hasBatterySystem: false,
            hasEvChargers: false,
            showSiteSummary: true,
        };

        // this.hasBatterySystemChange = this.hasBatterySystemChange.bind(this);
        this.hasEvChargersChange = this.hasEvChargersChange.bind(this);
        this.updateBuilding = this.updateBuilding.bind(this);
        this.updateBuildingEVC = this.updateBuildingEVC.bind(this);
        this.updateBuildingData = this.updateBuildingData.bind(this);
        this.submitAction = this.submitAction.bind(this);
    }

    componentWillMount() {
        // setTimeout(()=>{
        // let AppHeader = document.getElementsByClassName('App-header')[0];
        // AppHeader.style.background='white';
        // },500);
        this.setState({ building_data: this.props.building_data });
    }

    toggleSiteSummary = () => {
        let { showSiteSummary } = this.state;
        this.setState({ showSiteSummary: !showSiteSummary });
    };

    setNumEVChargers(value) {
        let numEVCEle = document.getElementById("num_ev_chargers");
        if (numEVCEle !== null) {
            numEVCEle.value = value;
        }
        return value;
    }

    updateBuildingEVC() {
        const building_list_ip = document.getElementById('building_list_ip').value;
        const numEVCEle = document.getElementById("num_ev_chargers").value
        if (_.isEmpty(building_list_ip.trim())) {
            alert("Please select your building");
            return;
        }
        if (_.isEmpty(numEVCEle.trim())) {
            alert("Please enter number of chargers");
            return;
        }
        this.updateBuilding("num_ev_chargers", numEVCEle);
    }

    updateBuildingData(newBuildingData) {
        this.setState({ building_data: newBuildingData });
        // this.props.sendBuildingsDataToForm(newBuildingData);
        this.updateAllBuildingsToForm();

    }

    updateBuilding(field, value) {
        let { building_data } = this.props;
        console.log("upd building_data", building_data);
        var building = building_data.filter(function (building) {
            let selectedBuildingEle = document.getElementById(
                "building_list_ip"
            ); //.options.selected.value;
            let buildingOptions = [];
            let selectedBuilding = "";
            if (selectedBuildingEle !== null) {
                buildingOptions = selectedBuildingEle.options;
                selectedBuilding =
                    buildingOptions[selectedBuildingEle.selectedIndex];
                console.log("selectedBuilding", selectedBuilding.value);
            }
            return building.building_name === selectedBuilding.value;
        });
        console.log("building", building);
        if (building !== null && building.length > 0) {
            building[0][field] = value;
        }

        this.updateBuildingData(building_data);
    }

    // hasBatterySystemChange = (e) =>{
    //   this.setState({hasBatterySystem:e.target.checked,
    //   });
    // }

    hasEvChargersChange = (e) => {
        this.setState({ hasEvChargers: e.target.checked });
    };

    updateAllChangesToForm() {
        this.props.handleChangeInput("pv_life_yrs");
        this.props.handleChangeInput("pv_cost_kwp");
        this.props.handleChangeInput("battery_life_cycles");
        this.props.handleChangeInput("battery_cost_kwh");
    }

    updateAllBuildingsToForm() {
        console.log("IP updating all building to form");
        let { building_data } = this.state;
        this.props.sendBuildingsDataToForm(building_data);
    }

    submitAction() {
        this.updateAllChangesToForm();
        this.updateAllBuildingsToForm();
        this.props.submitAction();
    }

    render() {
        let {
            //hasBatterySystem,
            hasEvChargers,
        } = this.state;
        let {
            building_data,
            resolveApiError,
            resolveApiResponseStatus,
            free_calls,
            max_free_calls,
        } = this.props;
        //console.log('render step',step,this.state)
        let buildingOptions = [];
        if (building_data !== undefined && building_data.length > 0) {
            for (let i = 0; i < building_data.length; ++i) {
                buildingOptions.push(
                    <option value={building_data[i].building_name}>
                        {building_data[i].building_name}
                    </option>
                );
            }
        }
        return (
            <div>
                {resolveApiError !== undefined && resolveApiError.length > 0 ? (
                    <div id="AppBanner">
                        <div className="alert alert-danger" role="alert">
                            {resolveApiError}
                        </div>
                    </div>
                ) : (
                        ""
                    )}
                {resolveApiResponseStatus === 200 &&
                    <AppBanner free_calls={free_calls} max_free_calls={max_free_calls} />}

                <div className="col-lg-12" style={{ background: "transparent" }}>
                    <div className="row">
                        <div
                            className="col-lg-4 p-2"
                        >
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Planned Solar Panel Costs
                                </div>

                                <div style={{ padding: 10 }}>
                                    <div className="col">
                                        <div className="form-group">
                                            <TitleWithInfo
                                                title="What would be the expected life of your solar setup in years?"
                                                info={HELPTEXT.SOLAR_SETUP} />
                                            <input
                                                type="number"
                                                style={{ width: '30%', display: 'block' }}
                                                id="pv_life_yrs"
                                                defaultValue={
                                                    this.props.pv_life_yrs !== undefined
                                                        ? this.props.pv_life_yrs
                                                        : 20
                                                }
                                                onChange={()=> this.props.handleChangeInput("pv_life_yrs")}
                                            />
                                            &nbsp;
                                        </div>
                                        <br />
                                        <div className="form-group">
                                            <TitleWithInfo
                                                title="What is the cost per kWp of solar installation for a PV installer in your area?"
                                                info={HELPTEXT.PV_INSTALLER} />
                                            <br />
                                            
                                            <input
                                                id="pv_cost_kwp"
                                                type="number"
                                                style={{ width: '30%'}}
                                                placeholder="1.0"
                                                defaultValue={
                                                    this.props.pv_cost_kwp !== undefined
                                                        ? this.props.pv_cost_kwp
                                                        : 1840
                                                }
                                                onChange={()=> this.props.handleChangeInput("pv_cost_kwp")}
                                            />{` ${this.props.currency} `}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 p-2"
                        >
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Planned Battery System
                                </div>

                                <div className="row" style={{ padding: "10px" }}>
                                    <div className="col">
                                        <div
                                            id="batterySystemBox"
                                        >
                                            <div className="form-group">
                                                <TitleWithInfo
                                                    title="What would be the maximum life cycles of your battery setup?"
                                                    info={HELPTEXT.BATTERY_SETUP} />

                                                <br />
                                                <input
                                                    id="battery_life_cycles"
                                                    type="number"
                                                    style={{ width: '30%', display: 'block' }}
                                                    placeholder="1.0"
                                                    defaultValue={
                                                        this.props
                                                            .battery_life_cycles
                                                            ? this.props
                                                                .battery_life_cycles
                                                            : 6000
                                                    }
                                                    onChange={()=> this.props.handleChangeInput("battery_life_cycles")}
                                                />
                                            &nbsp;
                                        </div>
                                            <br />
                                            <div className="form-group">
                                                <TitleWithInfo
                                                    title="What is the cost per kWh of Battery installation in your area?"
                                                    info={HELPTEXT.BATTERY_INSTALLATION} />
                                                <br />
                                                <input
                                                    id="battery_cost_kwh"
                                                    type="number"
                                                    style={{ width: '30%'}}
                                                    placeholder="1.0"
                                                    defaultValue={
                                                        this.props.battery_cost_kwh
                                                            ? this.props
                                                                .battery_cost_kwh
                                                            : 407
                                                    }
                                                    onChange={()=> this.props.handleChangeInput("battery_cost_kwh")}
                                                />{` ${this.props.currency} `}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-2">
                            <div className="full-border">
                                <div className="control-label-heading title-box">
                                    Planned EV Chargers
                                </div>

                                <div className="row" style={{ padding: "10px" }}>
                                    <div className="col">
                                        <div className="form-group">
                                            <TitleWithInfo
                                                title="Do you plan to install EV chargers?"
                                                info={HELPTEXT.INSTALL_EV_CHARGERS} />
                                            <label
                                                className="switch"
                                                style={{
                                                    position: "absolute",
                                                    right: "24px",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="hasEvChargers"
                                                    onChange={
                                                        this.hasEvChargersChange
                                                    }
                                                />
                                                <span
                                                    className="slider"
                                                    style={{ color: "white" }}
                                                >
                                                    &nbsp;&#10004;
                                            </span>
                                            </label>
                                        </div>
                                        <div
                                            id="evChargersBox"
                                            style={{
                                                display: hasEvChargers
                                                    ? "block"
                                                    : "none",
                                            }}
                                        >
                                            <div className="form-group">
                                                <TitleWithInfo
                                                    title="Select your building"
                                                    info={HELPTEXT.BUILDING} />
                                                <select id="building_list_ip">
                                                    <option value="">
                                                        --Please Select--
                                                </option>
                                                    {buildingOptions}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                How Many?
                                            <br />
                                                <input
                                                    id="num_ev_chargers"
                                                    type="number"
                                                    style={{ width: "70px" }}
                                                />
                                                <br />
                                                <div style={{ width: "100%" }}>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-sm btn-success"
                                                onClick={this.updateBuildingEVC}
                                            >
                                                Add
                                        </button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SiteSummaryPage
                        showSiteSummary={this.state.showSiteSummary}
                        address={this.props.address}
                        building_data={this.state.building_data}
                        sendBuildingsDataToForm={this.updateBuildingsData}
                    />

                    <BottomNavigationBar
                    isOutput={true}
                        onSiteSummaryClick={() => {
                            this.updateAllChangesToForm();
                            this.updateAllBuildingsToForm();
                            this.toggleSiteSummary();
                        }}
                        onLeftButtonClick={() => {
                            this.updateAllChangesToForm();
                            this.updateAllBuildingsToForm();
                            this.props.prevStep();
                        }} >
                        <button
                            className="btn btn-success"
                            onClick={this.submitAction}
                            disabled={
                                resolveApiError.length > 0 ? "disabled" : ""
                            }
                        >
                            &nbsp;&nbsp;See your output&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </BottomNavigationBar>
                </div>
            </div>
        );
    }
}
