import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { SIGN_IN_AUTHORITY, APPLICATION_ID } from "../common/constants";

const reactRedirectUri = "https://lcapp.orxa.io/b2c";
//const reactRedirectUri = "http://localhost:3000/b2c";
//const reactRedirectUri = "http://localhost/b2c";
//const reactRedirectUri = "https://lcappv2test.azurewebsites.net/b2c";

const signInConfig = {
    auth: {
        authority: SIGN_IN_AUTHORITY,
        clientId: APPLICATION_ID,
        redirectUri: reactRedirectUri,
        validateAuthority: false,
        postLogoutRedirectUri: "https://lcapp.orxa.io/",
        //postLogoutRedirectUri: "http://localhost:3000/",
        //postLogoutRedirectUri: "http://localhost",
        //postLogoutRedirectUri: "https://lcappv2test.azurewebsites.net/",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
};
// Authentication Parameters
const authenticationParameters = {
    scopes: [
        "https://graph.microsoft.com/Directory.Read.All",
        "https://derapp.onmicrosoft.com/apirjs/demo.read"
    ]
};
// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + "/auth.html",
    persistLoginPastSession: true
};


export const signInAuthProviderB2C = new MsalAuthProvider(
    signInConfig,
    authenticationParameters,
    options
);

export const authProvider = new MsalAuthProvider(signInConfig, authenticationParameters, options);
 
export const authenticateRequestB2C = async (headers) => {
    // Get the authentication token 
    const token = await authProvider.getIdToken();
    return token.idToken.rawIdToken;
  };
