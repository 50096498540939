import React, { Component } from 'react';
import deleteButton from '../img/deleteButton.png';
var _ = require('lodash');

export default class SiteSummaryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: '',
            isCompassVisible: true,
            building_data: [],
        };
        this.deleteBuilding = this.deleteBuilding.bind(this);

    }

    componentWillMount() {
        this.setState({
            building_data: this.props.building_data,
            address: this.props.address,
            trigger: Math.random()
        })
    }

    deleteBuilding(buildingId) {
        let { building_data } = this.state;
        let new_building_data = building_data.filter(building => building.building_id !== buildingId);
        this.props.updateBuildingToParentPage(new_building_data)
        this.setState({ building_data: new_building_data, trigger: Math.random() });
    }

    render() {
        let { building_data, showSiteSummary } = this.props;
        return (
            <div id='SiteSummary' style={{ display: showSiteSummary ? 'block' : 'none' }} >
                <div className='' style={{ marginTop: '-30px', marginBottom: '30px', textAlign: 'center' }}>
                </div>
                <div className='col-lg-12' style={{ background: 'transparent' }}>
                    Location : {this.props.address}
                    <div className="row">
                        <div className='col-md-12 form-box'>
                            <div className="control-label-heading title-box">
                                Site Summary
                    </div>
                    <div className="table-responsive-md">
                            <table className='table table-sm table-light' >
                                <thead>
                                    <th>#</th>
                                    <th>Building Name</th>
                                    <th>Type</th>
                                    <th>Direction</th>
                                    <th>Slope</th>
                                    <th>Roof Size</th>
                                    <th>Annual Consumption</th>
                                    <th>No. EV Chargers</th>
                                    <th>Building Demand File</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {building_data?.map((element, index) =>
                                        <tr id={'row' + index + 1}>
                                            <td >{index + 1}</td>
                                            <td>{element.building_name}</td>
                                            <td>{_.startCase(_.toLower(element.building_type))}</td>
                                            <td>{element.azimuth_deg}&deg;</td>
                                            <td>{element.pitch_deg}&deg;</td>
                                            <td>{element.roof_size_m2} m<sup>2</sup></td>
                                            <td>{element.annual_kwh_consumption_optional} kWh</td>
                                            <td>{element.num_ev_chargers}</td>
                                            <td>{element.load_profile_csv_optional_bldg_fname || ' '}</td>
                                            <td><img src={deleteButton} style={{ width: '20px' }} onClick={(e) => {
                                                this.deleteBuilding(element.building_id);
                                            }}
                                                alt='' />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
