import React from 'react';
import '../App.css';

function Footer() {

  return (
    <div id="app-footer" style={{zIndex:'100',textAlign: 'right'}}>
      <strong>&copy; 2017-2020 Copyright. <a href="http://www.orxagrid.com/"> Orxa Grid </a></strong>All rights reserved.
     </div>    
  );
}

export default Footer;
