import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Footer from './components/Footer';
import logo from './img/ORXA_Logo_Wordmark.png';
import emoji from './img/googledogfaceemoji.png';
import * as serviceWorker from './serviceWorker';
//import HomePage from './components/HomePage';
// import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { signInAuthProviderB2C } from "./b2cmodule/authProvider";
import { signInAuthProviderAD } from "./admodule/authProvider";
let isAuthRequired = true;
let signInAuthProvider;

if (window.location.href.includes('/b2c')) {
    signInAuthProvider = signInAuthProviderB2C
}
else if (window.location.href.includes('/ad')) {
    signInAuthProvider = signInAuthProviderAD
}
else {
    isAuthRequired = false
}

isAuthRequired
    ?
    ReactDOM.render(
        <AzureAD provider={signInAuthProvider} forceLogin={true}>
            {
                ({ login, logout, authenticationState, error, accountInfo }) => {
                    // console.log('authenticationState',authenticationState)
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            return (
                                <>

                                    <React.Fragment>
                                        <App
                                            accountInfo={accountInfo} logout={logout}
                                        />
                                    </React.Fragment>
                                </>

                            );
                        case AuthenticationState.Unauthenticated:
                            return (
                                <div className="App" style={{ minHeight: '95vh' }}>
                                    <div className="jumbotron jumbotron-fluid" style={{ padding: '20px' }}>
                                        <div><img src={logo} className="logo" alt="OrxaGrid" style={{ width: '30vh' }} />
                                            <hr /><br />
                                        </div>
                                        <div style={{justifyContent: 'center', flexDirection: 'column', display: 'flex', minHeight: '74vh'}}>
                                            <div className="align-middle">
                                                <div className="text-center">
                                                    <span><img alt="" role="img" src={emoji} style={{ width: 150, height: 150 }} /></span>
                                                    {error && <p><div>Opps!!&#128542; An error occured during authentication!</div></p>}
                                                    <span>Please try again!
                                                        <br />
                                                        To Login on Sign Up <a style={{ color: 'blue', textDecoration: 'underline' }} onClick={login}>click here</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            );
                        case AuthenticationState.InProgress:
                            return (<p style={{color:"white"}}>Authenticating...</p>);

                        default:
                            return (<p style={{color:"white"}}>Loading...</p>);

                    }
                }
            }
        </AzureAD>,
        document.getElementById('root')
    )
    :

    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root')
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

