import React, { Component } from 'react';
import ResultsPage from './ResultsPage';
import StartSitePage from './StartSitePage';
import LocationDetailsPage from './LocationDetailsPage';
import UtilityPage from './UtilityPage';
import PlannedInstallationPage from './PlannedInstallationPage';
import axios from 'axios';
import { GENERIC_API_URL } from '../common/constants'
import SpinnerIcon from '../img/Ripple.gif';
import SUbNav from '../common/SubNav';
import Footer from './Footer';
import Spinner from '../common/Spinner';
import {authenticateRequestB2C} from '../b2cmodule/authProvider.js';
import {authenticateRequestAD} from '../admodule/authProvider.js';

//import test_result from './test_result.json' // testing only

export default class SolarForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            address: '',
            sub: ' ',
            oid: ' ',
            sub_id: ' ',
            plan_id: '',
            jwtIdToken: '',
            currency:'',
            country:'',
            imp_cost_kwh:'',
            exp_price_kwh:'',
            annual_kwh:0,
            buildingtype:'',
            expected_life_solar_years:0,
            solarpv_installation_cost_kwp:0,
            storage_battery_system_cost_kwh:0,
            discharge_cycles_battery:0,
            isCompassVisible: true,
            getCountryValues:null,
            building_data: [],
            results: {},
            html: '',
            firstName: 'John',
            resolveApiResponseStatus: '',
            resolveApiError: '',
            isLoadingResults: false,
            maxReachedStep: 0
        };

        this.updateBuildingsData = this.updateBuildingsData.bind(this);
        this.handleSubNavigation = this.handleSubNavigation.bind(this);
        this.validateAnnualConsumptionOrBldgDemand = this.validateAnnualConsumptionOrBldgDemand.bind(this);
        // this.resolveUserInfo=this.resolveUserInfo.bind(this);

    }


    componentWillMount() {
        let { accountInfo } = this.props;
        let firstName = ' ';
        let sub = ' ';
        let oid = ' ';
        let jwtIdToken = '';
        debugger;

        if (accountInfo !== undefined && accountInfo.account !== undefined) {
            if (accountInfo.account.name !== undefined)
                firstName = accountInfo.account.name.split(' ')[0];
            jwtIdToken = accountInfo.jwtIdToken;
            sub = accountInfo.account.idToken.sub;
            oid = accountInfo.account.idToken.oid;
            setTimeout(
                this.resolveUserInfo(jwtIdToken)
                , 3000);
         }
        this.setState({
            accountInfo: accountInfo,
            firstName: firstName,
            jwtIdToken: jwtIdToken,
            sub: sub,
            oid: oid,

        });


    }

    componentDidUpdate() {
        if (this.state.step > 0 && this.state.step < 4) {
            setTimeout(() => {
                let AppHeader = document.getElementsByClassName('App-header')[0];
                AppHeader.style.background = 'white';
                // let AppMenu = document.getElementById('menu1');
                // AppMenu.style.color='#2B2B2B';
            }, 500);
        } else {
            setTimeout(() => {
                let AppHeader = document.getElementsByClassName('App-header')[0];
                AppHeader.style.background = 'transparent';
                // let AppMenu = document.getElementById('menu1');
                // AppMenu.style.color='white';
            }, 500);
            console.log('cdu solar form props', this.props)
        }
    }

    async resolveUserInfo(jwtIdToken) {
        debugger;
        let sub_id = null;
        let plan_id = null;
        let free_calls = 0;
        let max_free_calls = 0;
        let resolveApiError = '';
        let resolveApiResponseStatus = 0;
        

        let headers = {
            'authorization': 'Bearer ' + jwtIdToken,
        };
        
        await axios.post(GENERIC_API_URL + 'resolve', {}, { headers: headers })
            .then(function (response) {
                //handle success
                debugger;
                console.log('resolve response', response, response.data);
                resolveApiResponseStatus = response.status;
                if (response.data !== undefined) {
                    free_calls = response.data.free_calls;
                    max_free_calls = response.data.max_free_calls;
                    sub_id = response.data.subscription_id;
                    plan_id = response.data.plan_id;
                }

            })
            .catch(function (error) {
                //handle error
                try{
                console.log('ressolve response err', error, error.response.status, error.response.data.error);
                resolveApiResponseStatus = error.response.status;
                resolveApiError = error.response.data.error;
                }catch(err){
                    console.log(err);
                }
            });

        if (resolveApiResponseStatus === 200) {
            this.setState({
                free_calls: free_calls,
                max_free_calls: max_free_calls,
                sub_id: sub_id,
                plan_id: plan_id,
                resolveApiResponse: true,
                resolveApiResponseStatus: resolveApiResponseStatus,
            });
        } else {
            this.setState({
                resolveApiResponseStatus: resolveApiResponseStatus,
                resolveApiError: resolveApiError,
                free_calls: free_calls,
                max_free_calls: max_free_calls,
                sub_id: sub_id,
                plan_id: plan_id,
            });
        }

    }

    validateAnnualConsumptionOrBldgDemand() {
        let { building_data , load_profile_csv_optional } = this.state;
        if(load_profile_csv_optional) return true;
        console.log('validateAnnualConsumptionOrBldgDemand', building_data)
        for (let i = 0; i < building_data.length; ++i) {
            if (building_data[i].annual_kwh_consumption_optional || building_data[i].load_profile_csv_optional_bldg) {
                continue;
            } else {
                alert('Please select annual consumption or demand file for building ' + building_data[i].building_name);
                return false;
            }
        }
        return true
    }

    nextStep = () => {
        const { step } = this.state;
        let { maxReachedStep } = this.state;
        if (maxReachedStep < step) maxReachedStep = step;
        if(step==1) {
            setTimeout(
                this.getCountryValues()
                , 3000);
        }

        if (step === 2) {
            if (!this.validateAnnualConsumptionOrBldgDemand()) {
                return;
            } 
        }
        this.setState({
            step: step + 1,
            address: '',
            maxReachedStep: maxReachedStep,
        });
    }

    prevStep = () => {
        const { step } = this.state;

        if (step === 2) {
            this.setState({currency:''});
        }

        this.setState({
            step: step - 1,
            errorObj: undefined,
        })
        
    }

    handleChange = input => {
        // //console.log('solarPVForm handlechange',input)
        let inputEle = document.getElementById(input)
        // //console.log('found',inputEle)
        if (inputEle !== null) {
            // //console.log('inputEle',inputEle.value)
            this.setState({
                [input]: inputEle.value,
            });
        }
    }

    updateBuildingsData(buildings) {
        console.log('form updateBuildingsdata', buildings, this.state)
        if (this.state === undefined) return;
        console.log('form updateBuildingsData buildings only', buildings)
        let { building_data } = this.state;
        building_data = buildings;
        this.setState({ building_data: building_data });
        console.log('form bldg updated', buildings, this.state)
    }

     async getCountryValues(){
        let { accountInfo } = this.props;
        let jwtIdToken = '';
        let resolveApiError = '';
        let resolveApiResponseStatus = 0;
        let annual_kwh = 0;
        let country = '';
        let currency = '';
        let imp_cost_kwh = 0.0;
        let exp_price_kwh = 0.0;
        let solarpv_installation_cost_kwp = 0;
        let storage_battery_system_cost_kwh = 0;
        let expected_life_solar_years = 0;
        let discharge_cycles_battery = 0;
        let {building_data} = this.state;
        let lat = document.getElementById("latitude").value;
        let lon = document.getElementById("longitude").value;
        let buildingtype = document.getElementById("building_type").value;
        debugger;
        if (accountInfo !== undefined && accountInfo.account !== undefined) {
            jwtIdToken = accountInfo.jwtIdToken;

        }
        console.log('get Country specific default Values', this.state)
        buildingtype = [];
        if(building_data.length>0)
            for (let i = 0; i < building_data.length; ++i) {
                buildingtype.push(building_data[i].building_type);
            }
          
        let bodyFormData = {
            "lat": lat,
            "lon": lon,
            "building_type": buildingtype
        };

        let headers = {
            'authorization': 'Bearer ' + jwtIdToken,
        };
        await axios.post(GENERIC_API_URL + 'default_country_values', bodyFormData, { headers: headers })
            .then(function (response) {
                //handle success
                debugger;
                console.log('default_country_values response', response, response.data);
                resolveApiResponseStatus = response.status;
                if (response.data !== undefined) {
                    country = response.data.country;
                    currency = response.data.currency;                    
                    annual_kwh = response.data.default_annual_kwh;
                    imp_cost_kwh = response.data.import_cost_kwh;
                    exp_price_kwh = response.data.export_price_kwh;
                    solarpv_installation_cost_kwp = response.data.solarpv_installation_cost_kwp;
                    storage_battery_system_cost_kwh = response.data.storage_battery_system_cost_kwh;
                    expected_life_solar_years = response.data.expected_life_solar_years;
                    discharge_cycles_battery = response.data.discharge_cycles_battery;
                }

            })
            .catch(function (error) {
                //handle error
                try{
                console.log('ressolve response err', error, error.response.status, error.response.data.error);
                resolveApiResponseStatus = error.response.status;
                resolveApiError = error.response.data.error;
                }catch(err){
                    console.log(err);
                }
            });

        if (resolveApiResponseStatus === 200) {

            document.getElementById("import_cost_kwh").value = imp_cost_kwh;
            document.getElementById("export_price_kwh").value = exp_price_kwh;
            document.getElementById("annual_kwh_consumption_optional").value = annual_kwh;

            this.setState({
                country:country,
                currency:currency,
                annual_kwh:annual_kwh,
                imp_cost_kwh:imp_cost_kwh,
                exp_price_kwh:exp_price_kwh,
                solarpv_installation_cost_kwp:solarpv_installation_cost_kwp,            
                storage_battery_system_cost_kwh:storage_battery_system_cost_kwh,
                expected_life_solar_years:expected_life_solar_years,
                discharge_cycles_battery:discharge_cycles_battery,
                pv_cost_kwp:solarpv_installation_cost_kwp,
                pv_life_yrs:expected_life_solar_years,
                battery_life_cycles:discharge_cycles_battery,
                battery_cost_kwh:storage_battery_system_cost_kwh,
                resolveApiResponse: true,
                resolveApiResponseStatus: resolveApiResponseStatus,
            });
        } else {
            this.setState({
                resolveApiResponseStatus: resolveApiResponseStatus,
                resolveApiError: resolveApiError,
            });
        }
     

    }
    

    submitAction = async () => {
        console.log('submit Action', this.state)
        // let {accountInfo} = this.state;
        // let jwtIdToken =  accountInfo.jwtIdToken;
        // let subid = accountInfo.account.idToken.sub;
        // let objid = accountInfo.account.idToken.oid;
        let { step, sub, oid, sub_id, plan_id, jwtIdToken } = this.state;
        let { latitude, longitude, import_cost_kwh, export_price_kwh, pv_cost_kwp, pv_life_yrs,
            battery_life_cycles, battery_cost_kwh, load_profile_csv_optional, building_data,solarpv_installation_cost_kwp,
            storage_battery_system_cost_kwh,expected_life_solar_years,discharge_cycles_battery,country,currency} = this.state;
        this.setState({ isLoadingResults: true });
        let building_data_formatted = [];
        for (let i = 0; i < building_data.length; ++i) {
            console.log('building_data[i].num_ev_chargers,', building_data[i].num_ev_chargers,)
            let buildingItem = {
                name: building_data[i].building_name,
                building_type: building_data[i].building_type,
                roof_size_m2: parseInt(building_data[i].roof_size_m2),
                azimuth_deg: parseInt(building_data[i].azimuth_deg),
                pitch_deg: parseInt(building_data[i].pitch_deg),
                num_ev_chargers: building_data[i].num_ev_chargers !== undefined ? parseInt(building_data[i].num_ev_chargers) : 0,
                pv_size_kwp_optional: building_data[i].pv_size_kwp_optional !== undefined ? parseFloat(building_data[i].pv_size_kwp_optional) : 1,
                load_profile_csv_optional: building_data[i].load_profile_csv_optional_bldg !== undefined ? building_data[i].load_profile_csv_optional_bldg : '',
                annual_kwh_consumption_optional: building_data[i].annual_kwh_consumption_optional !== undefined ? parseFloat(building_data[i].annual_kwh_consumption_optional) : 2400
            }
            building_data_formatted.push(buildingItem);
        }
        //console.log('bat',battery_cost_kwh,battery_cost_kwh!==null,battery_cost_kwh!==null?parseInt(battery_cost_kwh):407)
        let bodyFormData = {
            "sub": sub,
            "oid": oid,
            "sub_id": sub_id,
            "plan_id": plan_id,
            "lat": parseFloat(latitude),
            "lon": parseFloat(longitude),
            "country":country,
            "currency":currency,
            "import_cost_kwh": parseFloat(import_cost_kwh),
            "export_price_kwh": parseFloat(export_price_kwh),
            "pv_cost_kwp": parseInt(pv_cost_kwp !== undefined ? pv_cost_kwp : this.state.solarpv_installation_cost_kwp),
            "pv_life_yrs": parseInt(pv_life_yrs !== undefined ? pv_life_yrs : this.state.expected_life_solar_years),
            "battery_life_cycles": battery_life_cycles !== undefined ? parseInt(battery_life_cycles) : this.state.discharge_cycles_battery,
            "battery_cost_kwh": battery_cost_kwh !== undefined ? parseInt(battery_cost_kwh) : this.state.storage_battery_system_cost_kwh,
            "load_profile_csv_optional": load_profile_csv_optional,
            "building_data": building_data_formatted
        };

        let jwtIdTokennew=''
              
        //get fresh id token on expiry
        if (window.location.href.includes('/b2c')){
            await authenticateRequestB2C({}).then(function(result) {
                console.log("token: ", result);
                jwtIdTokennew = result;
            });
        }else{
            await authenticateRequestAD({}).then(function(result) {
                console.log("token: ", result);
                jwtIdTokennew = result;
            });
        }

        let headers = {
            'authorization': 'Bearer ' + jwtIdTokennew,
        };

        //console.log('submit request json',JSON.stringify(bodyFormData))
        let results, charts, errorObj, html;
        await axios.post(GENERIC_API_URL + 'optimise', bodyFormData, { headers: headers })
            .then(function (response) {
                //handle success
                debugger;
                console.log('optimize response', response, response.data);
                const regex = /\\n|\\r\\n|\\n\\r|\\r|\\t|\\/g;

                if (response.data.results.site.success) {
                    results = response.data.results;
                    //charts = response.data.charts;
                    html = response.data.html.replace(regex, '');
                    step = step + 1;
                } else {
                    results = 'Oops! Something went wrong! we\'ll analyze it soon'
                }
            })
            .catch(function (error) {
                //handle error
                console.log('submit response err', error, error.response);//,error.response.status,error.response.data.error);
                if (error.response !== undefined) {
                    errorObj = { errorStep: step, status: error.response.status, message: error.response.data.error };
                    if (errorObj.status === 402) {
                        alert('You have not subscribed to our app. You will be redirected to Azure market place in order to subscribe to our app. Click Ok button!!!');
                        // window.location.href = "https://azuremarketplace.microsoft.com/en-us/marketplace/apps/orxagrid1584097142796.lcappv2?tab=Overview";
                    }
                } else {
                    errorObj = { errorStep: step, status: 500, message: error.response }
                }
            });
        this.setState({
            step: step,
            results: results,
            //charts: charts,
            html,
            isLoadingResults: false,
            errorObj: errorObj,
        });
        // console.log('state after submit',this.state.step,this.state.results)
    }

    handleSubNavigation(stepName) {
        switch (stepName) {
            case 'Location':
                this.setState({ step: 1 });
                break;
            case 'Utility':
                this.setState({ step: 2 });
                break;
            case 'Installation':
                this.setState({ step: 3 });
                break;
            default:
                this.setState({ step: 0 });
                break;
        }
    }

    render() {
        let { step, isLoadingResults, maxReachedStep } = this.state;
        switch (step) {
            case 0:
                return (
                    <div className="d-flex flex-column" style={{ height: 'calc(100vh - 87px)' }}>
                        <div className="d-flex flex-2">
                            <div className="container align-self-center">
                                <StartSitePage
                                    firstName={this.state.firstName}
                                    plan_id={this.state.plan_id}
                                    max_free_calls={this.state.max_free_calls}
                                    free_calls={this.state.max_free_calls - this.state.free_calls}
                                    resolveApiResponseStatus={this.state.resolveApiResponseStatus}
                                    resolveApiError={this.state.resolveApiError}
                                    nextStep={this.nextStep}
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <Footer />
                        </div>
                    </div>
                );
            // break;
            case 1:
                return (
                    <div style={{ background: 'white', minHeight: '84vh', borderTop: '2px solid #4DA858' }}>
                        <SUbNav option={step} maxReachedStep={maxReachedStep} handleSubNavigation={this.handleSubNavigation} />
                        <LocationDetailsPage
                            address={this.state['pac-input']}
                            building_data={this.state.building_data}
                            max_free_calls={this.state.max_free_calls}
                            free_calls={this.state.free_calls}
                            resolveApiResponseStatus={this.state.resolveApiResponseStatus}
                            resolveApiError={this.state.resolveApiError}
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChangeInput={this.handleChange}
                            sendBuildingsDataToForm={this.updateBuildingsData}
                        />
                        <Footer />
                    </div>);
            //break;
            case 2:
                return (<div style={{ background: 'white', minHeight: '84vh', borderTop: '2px solid #4DA858' }}>
                    <SUbNav option={step} maxReachedStep={maxReachedStep} handleSubNavigation={this.handleSubNavigation} />
                    <UtilityPage
                        address={this.state['pac-input']}
                        building_data={this.state.building_data}
                        latitude={this.state.latitude}
                        longitude={this.state.longitude}
                        currency={this.state.currency}
                        buildingtype={this.state.buildingtype}
                        import_cost_kwh={this.state.imp_cost_kwh}
                        export_price_kwh={this.state.exp_price_kwh}
                        annual_kwh_consumption_optional={this.state.annual_kwh}
                        load_profile_csv_optional={this.state.load_profile_csv_optional}
                        load_profile_csv_optional_site_fname={this.state.load_profile_csv_optional_site_fname}
                        max_free_calls={this.state.max_free_calls}
                        free_calls={this.state.free_calls}
                        resolveApiResponseStatus={this.state.resolveApiResponseStatus}
                        resolveApiError={this.state.resolveApiError}
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChangeInput={this.handleChange}
                        sendBuildingsDataToForm={this.updateBuildingsData}
                    />
                    <Footer />
                </div >);
            // break;
            case 3:
                return (<div style={{ background: 'white', minHeight: '84vh', borderTop: '2px solid #4DA858' }}>
                    <SUbNav option={step} maxReachedStep={maxReachedStep} handleSubNavigation={this.handleSubNavigation} />
                    {isLoadingResults && <Spinner />}
                    <PlannedInstallationPage
                        address={this.state['pac-input']}
                        building_data={this.state.building_data}
                        pv_life_yrs={this.state.expected_life_solar_years}
                        pv_cost_kwp={this.state.solarpv_installation_cost_kwp}
                        battery_life_cycles={this.state.discharge_cycles_battery}
                        battery_cost_kwh={this.state.storage_battery_system_cost_kwh}
                        currency = {this.state.currency}
                        max_free_calls={this.state.max_free_calls}
                        free_calls={this.state.free_calls}
                        resolveApiResponseStatus={this.state.resolveApiResponseStatus}
                        resolveApiError={this.state.resolveApiError}
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChangeInput={this.handleChange}
                        sendBuildingsDataToForm={this.updateBuildingsData}
                        submitAction={this.submitAction}
                    />
                    <Footer />
                </div>);
            case 4:
                return (
                    <div>
                        <ResultsPage
                            results={this.state.results}//{test_result}//{this.state.results}
                            //charts={this.state.charts}
                            html={this.state.html}
                            prevStep={this.prevStep}
                        />
                        <Footer />
                    </div>
                )
            //break;
            default:
                return (<div className="jumbotron" style={{ background: 'white', height: 'auto', borderTop: '2px solid #4EA758' }}>
                    404 Page Not Found. Please contact Administrator
                </div>);

            //break;
        }


    }

}
