import React from 'react';
import SpinnerIcon from './SpinnerIcon';

const Spinner = () => {
    return (
        <div
            className="Spinner"
            style={{
                background: 'rgba(0,0,0,.5)',
                height: '100vh',
                position: "absolute",
                width: '100%',
                display: 'flex',
                zIndex: 9999,
                marginTop:'-87px'
            }}>
            <SpinnerIcon />
        </div>
    )
}

export default Spinner;
